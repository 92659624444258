.group:after {
    visibility: hidden;
    display: block;
    content: "";
    clear: both;
    height: 0;
}

:focus {
    outline: 1px solid $blue-500;
    outline-offset: 2px;
}