.blog-figure-row {
  display: flex;
  flex-direction: row;

  figure {
    flex: 1;
    &:not(:last-child) {
      margin-right: 2em;
    }
  }

  margin-bottom: 2em;
}

@media (max-width: 600px) {
  .blog-figure-row {
    display: block;
    figure,
    figure:not(:last-child) {
      margin: 0;
      margin-bottom: 1em;
    }
  }
}

.post-date {
  font-size: 14px;
  margin-top: -1em;
  margin-bottom: 2em;
}
