h1, h2, h3, h4 {
    font-family: $heading-font;
    margin: 1em 0;
    font-weight: 500;
    color: $gray-000;
}

h2 {
    &:before {
        display: inline-block;
        color: $border-color;
        content: "\00b6";
        text-align: right;
        width: 20px;
        padding-right: 8px;
        margin-left: -28px;
    }

    margin-top: 1.2em;
    border-bottom: 1px solid $border-color;
    padding-bottom: 4px;
}

h1 {
    font-size: 2.0em;
    font-weight: 300;
}

h2 {
    font-size: 1.4em;
}

h3 {
    font-size: 1.2em;
}

h4 {
    font-size: 1.0em;
}

sup {
    vertical-align: super;
    font-size: 0.7em;
}

sub {
    vertical-align: sub;
    font-size: 0.7em;
}

p {
    margin: 1em 0;
    line-height: 1.2em;
}

p img {
    max-width: 100%;
}

figure {
    img {
        max-width: 100%;
    }
}

p.lead {
    font-weight: 300;
    font-size: 1.2em;

    b, strong {
        font-weight: 500;
    }
}

ul, ol {
    margin: 1em 0;
    margin-left: 2em;
}

ul {
    list-style-type: disc;
}

ol {
    list-style-type: decimal;
}

li {
    line-height: 1.6em;
}

a {
    transition: color $transition-default;

    &, &:visited {
        color: $link-color;
        text-decoration: underline;
    }

    &:hover, &:active {
        color: $highlight-color;
    }
}

b, strong {
    font-weight: 700;
}

i, em {
    font-style: italic;
}

small {
    font-size: 0.9em;
}

hr {
    margin: 1em 0;
    padding: 0;
    height: 0;
    border: none;
    border-top: 1px solid $border-color;
}

table {
    margin: 1em 0;
    border-collapse: collapse;

    td, th {
        border: 1px solid $border-color;
        padding: 0.6em 1em;
    }

    thead {
        font-weight: 500;
        background-color: $gray-800;
    }

    tbody {
        tr {
            td {}
        }
    }
}

pre {
    font-family: $mono-font;
    font-size: 0.9em;
    line-height: 1.5em;
}

code {
    font-family: $mono-font;
    font-size: 0.9em;
    color: $code-color;
    padding: 0.2em 0.3em;
    border-radius: 2px;
    background: rgba(0, 0, 0, 0.04);
}

pre {
    code {
        background: none;
    }

    font-size: 1em;
    padding: 1em;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.02);
}

code.hljs {
    padding: 0;
}

code.hljs {
    padding: 0;
}
