// @import url("https://use.typekit.net/lrx2vpw.css");
// $main-font: "ff-tisa-web-pro", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
// $mono-font: "Monaco", "Menlo", monospace;

// @import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700|Lato:300,300i,400,400i,700,700i');
// $main-font: "Lato", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
// $mono-font: "Inconsolata", "Monaco", "Menlo", monospace;

// @import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700|Quicksand:300,400,500,700');
// $main-font: "Quicksand", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
// $mono-font: "Inconsolata", "Monaco", "Menlo", monospace;

// @import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700|Raleway:300,300i,400,400i,700,700i');
// $main-font: "Raleway", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
// $mono-font: "Inconsolata", "Monaco", "Menlo", monospace;

// @import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700|Muli:300,300i,400,400i,700,700i');
// $main-font: "Muli", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
// $mono-font: "Inconsolata", "Monaco", "Menlo", monospace;

// @import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700|PT+Serif:400,400i,700,700i|Nunito:300,300i,400,400i,700,700i');
@import url('../fonts/fonts.css');
$main-font: "PT Serif", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$heading-font: "Nunito", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$mono-font: "Inconsolata", "Monaco", "Menlo", monospace;

$main-font-size: 16px;

$main-width: 1000px;

$blue-100: #293347; // 21,14,278 - 63,58,278
$blue-200: #344b71;
$blue-300: #3d649e;
$blue-400: #447ecd;
$blue-500: #489aff;

$orange-100: #502813;
$orange-200: #783816;
$orange-300: #a34817;
$orange-400: #d05917;
$orange-500: #fe6a15;

$gray-000: #1c1c1c;
$gray-100: #323232;
$gray-200: #4a4a4a;
$gray-300: #636363;
$gray-400: #7d7d7d;
$gray-500: #989898;
$gray-600: #b4b4b4;
$gray-700: #d1d1d1;
$gray-800: #efefef;

$main-color: $gray-200;
$code-color: #3182bd;

$link-color: #000000;
$border-color: $gray-800;
$gray-color: $gray-500;
$highlight-color: $blue-500;

$transition-default: 0.2s cubic-bezier(0.4, 0, 0.2, 1);