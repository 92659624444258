@import "reset";
@import "variables";
@import "utils";
@import "typography";
@import "highlight";
@import "layout";
@import "blog";

$padding-horizontal: 20px;

body {
  font-family: $main-font;
  font-size: $main-font-size;
  color: $main-color;
  box-sizing: border-box;
  background: url("../images/crossword.png");
}

.navigation a.icon {
  display: none;
  &:hover,
  &.active {
    &:before {
      display: none;
    }
  }
}

@media screen and (max-width: 700px) {
  .navigation a {
    display: none;
  }
  .navigation a.icon {
    float: right;
    display: block;
  }
}

/* The "responsive" class is added to the topnav with JavaScript when the user clicks on the icon. This class makes the topnav look good on small screens (display the links vertically instead of horizontally) */
@media screen and (max-width: 700px) {
  .navigation.responsive {
    position: relative;
  }
  .navigation.responsive a.icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .navigation.responsive a {
    float: none;
    display: block;
    text-align: left;
  }
}

header {
  margin: 0px auto;
  max-width: $main-width;

  .navigation {
    position: relative;

    // background: rgba(0, 0, 0, 0.05);
    padding: 0;
    min-height: 48px;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background: $border-color;
    }
  }

  label {
    font-size: 1.2em;
    margin-right: 1em;
    color: $gray-200;
    font-family: $heading-font;
    height: 48px;
    line-height: 48px;
  }

  a {
    font-family: $heading-font;
    display: inline-block;
    font-size: 1.2em;
    line-height: 48px;
    height: 48px;
    text-decoration: none;
    font-weight: 500;
    padding: 0 1em;
    position: relative;

    &,
    &:visited {
      color: $gray-400;
    }

    transition: color $transition-default;

    &:before {
      content: " ";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 0px;
      transition: height $transition-default,
        background-color $transition-default;
    }

    &.active {
      color: $gray-000;

      &:before {
        height: 2px;
        background: $gray-500;
      }
    }

    &:hover {
      color: $blue-500;

      &:before {
        height: 2px;
        background: $blue-500;
      }
    }
  }

  padding: 0px $padding-horizontal;
}

section {
  margin: 20px auto;
  padding: 0 $padding-horizontal;
  max-width: $main-width;
}

section.with-sidebar {
  display: flex;
  flex-direction: column;
  flex-flow: initial;
  margin-top: 2em;

  & > aside {
    margin: 0;
    flex: 0 0 auto;
    padding-right: 1em;
  }

  & > .content {
    padding-left: 1em;
    flex: 1 1 auto;

    h1 {
      margin-top: 0;
    }
  }
}

footer {
  margin: 20px auto;
  padding: 0 20px;
  max-width: $main-width;
  font-size: 0.8em;
  color: $gray-color;
}

.publication-item {
  margin-bottom: 1em;

  .publication-title {
    font-weight: 700;
    display: block;
    font-family: $heading-font;
  }

  .publication-authors,
  .publication-controls {
    display: block;
    font-size: 0.9em;
  }

  .publication-note {
    color: $gray-400;
    display: block;
    font-size: 0.9em;
    b,
    strong {
      color: $main-color;
    }
  }

  .publication-where {
    color: $gray-400;
    font-style: italic;
    display: block;
    font-size: 0.9em;
  }

  .publication-controls {
    font-size: 0.9em;

    & > span:not(:first-child) {
      &:before {
        content: " | ";
        color: $gray-400;
      }
    }

    & > span:first-child {
      &:before {
        content: "[ ";
        color: $gray-400;
      }
    }

    & > span:last-child {
      &:after {
        content: " ]";
        color: $gray-400;
      }
    }
  }
}

.project-item {
  display: flex;
  flex-direction: row;

  .project-item-image {
    flex-shrink: 0;
    width: 200px;
    margin-right: 1em;
    margin-top: 1em;

    .project-image {
      width: 200px;
      height: 150px;
      background-size: cover;
      background-position: 0% 0%;
      background-repeat: no-repeat;
      transition: ease 750ms background-position;
      margin-bottom: 1em;

      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);

      &:hover {
        background-position: 100% 100%;
      }
    }
  }

  .project-item-content {
    flex: 1;
  }

  margin-bottom: 1em;

  &:not(:last-child) {
    padding-bottom: 1em;
    border-bottom: 1px solid $border-color;
  }
}

.post-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  &-item {
    display: block;
    margin-bottom: 1em;
    a {
      display: block;
      color: $main-color;
      text-decoration: none;

      padding: 1em 1em;
      background: white;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
      border: 1px solid white;
      transition: box-shadow $transition-default, border $transition-default,
        color $transition-default;

      &:hover {
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
        color: $highlight-color;
        border: 1px solid lighten($highlight-color, 10%);
      }

      .el-title {
        font-size: 1.2em;
        font-family: $heading-font;
      }
      .el-date {
        font-size: 0.8em;
      }
    }
  }
}

.photo-gallery-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  $margin: 20px;
  margin: 0 -$margin;
  .gallery-item {
    text-align: center;
    margin: $margin * 2 10px;
    .el-description {
      margin-top: 5px;
      font-size: 14px;
    }
    .el-image {
      img {
        max-width: 312px;
        max-height: 312px;
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
        padding: 0;
        margin: 0;
        // width: 100%;
      }
    }
    &.is-large {
      width: 90%;
      .el-image {
        width: 100%;
        img {
          max-width: none;
          max-height: none;
          height: auto;
          width: 100%;
        }
      }
    }
    &.is-full {
      margin: $margin * 2 $margin;
      width: 100%;
      .el-description {
        margin-top: 5px;
        font-size: $main-font-size;
      }
    }
  }
}

.art-gallery-list {
  .gallery-item {
    margin-top: 2em;
    margin-bottom: 3em;

    .el-title {
      margin-bottom: 0.3em;
      font-weight: 700;
      p {
        margin: 0;
      }
    }
    .el-subtitle {
      font-size: 0.8em;
      margin-bottom: 0.6em;
    }
    .el-description {
      font-size: 0.9em;
      p {
        margin: 0;
        margin-bottom: 0.3em;
      }
    }

    img {
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
    }
  }
}

audio {
  width: 100%;
  max-width: 400px;
  display: none;
}

.audio-player {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 30px;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: row;
  border: 1px solid rgba(0, 0, 0, 0.05);
  &.playing {
    border: 1px solid rgba(0, 0, 0, 0.4);
  }
  //   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  $svg_play: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxwb2x5Z29uIHBvaW50cz0iNy40OTkwMjM0LDYuMzM5MzU1NSAyMi41LDE1IDcuNDk5MDIzNCwyMy42NjAxNTYyICIvPjwvc3ZnPg0K");
  $svg_pause: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxyZWN0IHg9IjE3LjM5ODQzNzUiIHk9IjcuMTM5NjQ4NCIgd2lkdGg9IjUuMTAxNTYyNSIgaGVpZ2h0PSIxNS43MjE2Nzk3Ii8+PHJlY3QgeD0iNy40OTkwMjM0IiB5PSI3LjEzOTE2MDIiIHdpZHRoPSI1LjEwMTU2MjUiIGhlaWdodD0iMTUuNzIxMTkxNCIvPjwvc3ZnPg0K");
  $svg_download: url("data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9JzMwMHB4JyB3aWR0aD0nMzAwcHgnICBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGRhdGEtbmFtZT0iTGF5ZXIgMSIgdmlld0JveD0iMCAwIDE2IDE2IiB4PSIwcHgiIHk9IjBweCI+PHRpdGxlPmljb248L3RpdGxlPjxwYXRoIGQ9Ik0xLjI1LDExYS43NS43NSwwLDAsMSwxLjUsMHYyLjI1aDEwLjVWMTFhLjc1Ljc1LDAsMCwxLDEuNSwwdjNhLjc2Ljc2LDAsMCwxLS43NS43NUgyQS43Ni43NiwwLDAsMSwxLjI1LDE0Wk04LDEuMjVBLjc2Ljc2LDAsMCwwLDcuMjUsMlY4LjE5TDUuNyw2LjY0QS43NS43NSwwLDAsMCw0LjY0LDcuN2wyLjgzLDIuODNhLjc4Ljc4LDAsMCwwLC4yNC4xNi43My43MywwLDAsMCwuNTgsMCwuNzguNzgsMCwwLDAsLjI0LS4xNkwxMS4zNiw3LjdBLjc1Ljc1LDAsMCwwLDEwLjMsNi42NEw4Ljc1LDguMTlWMkEuNzYuNzYsMCwwLDAsOCwxLjI1WiI+PC9wYXRoPjwvc3ZnPg==");

  .ap-play,
  .ap-download {
    flex-shrink: 0;
    -webkit-appearance: none;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    display: inline-block;
    width: 30px;
    height: 30px;
    background-color: rgba(0, 0, 0, 0.02);
    cursor: pointer;

    background-size: 60% auto;
    background-position: 50%;
    background-repeat: no-repeat;

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
    &:focus {
      outline: 1px solid $blue-500;
      outline-offset: 0;
    }
  }

  .ap-play {
    background-image: $svg_play;
  }
  .ap-download {
    background-image: $svg_download;
  }

  &.playing {
    .ap-play {
      background-image: $svg_pause;
    }
  }

  .ap-progress {
    flex: 1;
    position: relative;
    width: calc(100% - 40px - 80px);
    margin-left: 10px;
    margin-right: 10px;
    height: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: white;
    cursor: pointer;

    .ap-region {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0%;
      background-color: rgba(0, 0, 0, 0.2);
    }

    .ap-loaded {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0%;
      background-color: rgba(0, 0, 0, 0.1);
    }

    .ap-indicator {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: calc(20% - 5px);
      width: 10px;
      background-color: black;
    }
  }

  .ap-time {
    flex-shrink: 0;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: right;
    padding-right: 10px;

    // background-color: rgba(0, 0, 0, 0.1);
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .project-item {
    flex-direction: column;
  }
}

audio {
  max-width: 500px;
  width: 100%;
}
