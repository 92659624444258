.grid-row {
    display: flex;
    flex-direction: row;

    .grid-col-3 {
        flex-shrink: 0;
        width: 25%;
        margin-right: 1em;
    }
    .grid-col-fill{
        flex: 1;
    }
}

@media(max-width: 600px) {
    .grid-row {
        flex-direction: column;

        .grid-col-3 {
            width: 100%;
        }
    }
}